.filters {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
  width: 100%;
  flex-wrap: nowrap;
}

.monday-style-dialog-content-wrapper {
  z-index: 9999;
}

.success-message {
  display: flex;
  align-items: center;
  height: 32px;
}

.success-chip div {
  color: rgb(1, 74, 1);
  font-weight: 500;
}

.filter-dropdown {
  position: absolute;
  top: 30px;
  left: 0;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  z-index: 10;
  text-align: left;
}

.filter-button {
  position: relative;
}

.filter-option {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: var(--primary-text-color);
}

.filter-option:hover {
  background-color: var(--primary-selected-color);
}

.filter-changed {
  background-color: var(--changed-filter-color) !important;
}

.checkmark {
  height: fit-content;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
}
.checkmark path {
  fill: var(--changed-filter-color);
}
