.table-row {
  cursor: pointer;
}

.assigned-on-search {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-wrap: nowrap;
  flex-wrap: wrap;
}

.input-label {
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left;
}

.search-btn {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-left: 2px solid var(--border-color);
}

.menu-style-invalid {
  border-color: #d83a52 !important;
}

.date-filter-style button {
  height: 32px;
}

.date-filter-style {
  margin-top: auto;
}

.date-picker-validation {
  border: 1px solid #d83a52;
  border-radius: 5px;
}

.show-info {
  width: fit-content;
  text-align: left;
  padding: 4px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: 400ms all;
}

.show-info-button {
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 8px;
}

.show-info:hover {
  background-color: var(--primary-selected-color);
  transition: 400ms all;
}

.info-content {
  text-align: left;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 20px;
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.5s ease-in-out,
    padding 0.5s ease-in-out;
}

.sticky-cell {
  position: sticky !important;
  left: 0 !important;
  z-index: 2;
  background-color: rgb(248, 248, 248);
  border: none;
  border-bottom: 1px solid rgb(196, 196, 196);
  box-shadow: 0px 0 5px #888;
  clip-path: inset(0px -5px 0px 0px);
}

.sticky-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 3;
}

.loader-style {
  max-width: 40px;
  max-height: 40px;
}

.info-content.expanded {
  max-height: 230px;
  padding: 10px 0;
}

.info-content.collapsed {
  max-height: 0;
  padding: 0;
}

@media only screen and (min-width: 613px) and (max-width: 733px) {
  .search-btn {
    border-color: transparent;
  }
}
