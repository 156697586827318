.fetching-message {
  display: flex;
  align-items: baseline;
  gap: 6px;
}

.fetching-loading {
  max-height: 20px;
  max-width: 20px;
  margin-top: -6px !important;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 30px;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

.input-style {
  background-color: var(--secondary-background-color);
  border-color: var(--border-color);
}
