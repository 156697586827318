.menu-style {
  z-index: 9998;
  margin-bottom: 1rem;
}

.disabled-cell {
  background-color: lightgray !important;
}

.chip-style {
  background-color: transparent !important;
}

.dropdown-menu {
  width: 100%;
}

.no-items {
  padding: 1rem;
  display: flex;
}

.create-item-content {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.header-column {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: 'none';
  cursor: col-resize;
}

.header-style {
  border-right: 1px solid #ddd;
  background: #f7f7f7;
  font-weight: bold;
  position: relative;
}

.create-item-dropdown {
  width: 200px;
  z-index: 10;
}

.table-container {
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
  height: fit-content;
  max-height: 300px;
  overflow-y: scroll;
}

.table-container table {
  width: 100%;
  border-spacing: 0;
  overflow: auto !important;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  color: rgb(81, 81, 81);
}

.table-container thead {
  background-color: #0073ea;
  text-align: left;
  position: sticky;
}

.table-container th {
  padding: 12px 15px;
  font-weight: bold;
  border-bottom: 2px solid var(--border-color);
  background-color: var(--secondary-background-color);
  color: var(--primary-text-color);
}

.table-container tbody {
  background-color: white;
  max-height: 150px;
  overflow: scroll;
}

.table-body {
  max-height: 150px;
  overflow: scroll;
}

.table-container td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--primary-text-color);
  background-color: var(--secondary-background-color);
}

.table-container tbody tr:nth-child(even) {
  background-color: #f7faff;
}

.table-container tbody tr:hover {
  background-color: #e3f2fd;
}

.table-row-interaction {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-cell {
  font-weight: bold;
  color: #0073ea;
}

.text-area-style textarea {
  field-sizing: content;
}

.sticky-cell {
  position: sticky !important;
  left: 0 !important;
  z-index: 2;
  background-color: rgb(248, 248, 248);
  border: none;
  border-bottom: 1px solid rgb(196, 196, 196);
  box-shadow: 0px 0 5px #888;
  clip-path: inset(0px -5px 0px 0px);
}

.sticky-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 3;
}

.table-date-picker {
  width: 200px;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 16px;
}
