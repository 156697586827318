.App {
  height: 100vh;
  overflow-y: scroll;
  text-align: center;
  margin: 1rem;
}

body {
  font-family: 'Figtree', sans-serif;
  padding-bottom: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dark-theme {
  background-color: #171b35;
  color: #d3d6e6;
  --primary-text-color: #d3d6e6;
  --primary-selected-color: #252844;
  --secondary-background-color: #2f324d;
  --border-color: #797e93;
  --disabled-background-color: #42434f;
  --disabled-text-color: #d3d6e6;
  --changed-filter-color: #3ea83e;
}

.light-theme {
  background-color: white;
  color: #404042;
  --primary-text-color: #404042;
  --primary-selected-color: #cce5ff;
  --secondary-background-color: #f8f8f8;
  --border-color: #c3c6d4;
  --disabled-background-color: #d7d7d7;
  --disabled-text-color: #67686a;
  --changed-filter-color: #3ea83e;
}

.black-theme {
  background-color: #111111;
  color: #c8c8c8;
  --primary-text-color: #c8c8c8;
  --primary-selected-color: #242424;
  --secondary-background-color: #2c2c2c;
  --border-color: #8d8d8d;
  --disabled-background-color: #595959;
  --disabled-text-color: #d3d6e6;
  --changed-filter-color: #3ea83e;
}

.menu-wrapper-style {
  background-color: var(--secondary-background-color) !important;
}
