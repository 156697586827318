.close-icon {
  background-color: #0073ea;
}

.date-picker-dialog {
  z-index: 15;
}

.date-picker {
  z-index: 15;
  margin-left: 1rem;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
}

.custom-date-content {
  display: flex;
  align-items: center;
  gap: 2px;
}
